import React from "react";
import { useWindowDimensions } from "../../hooks/useWindowDimensions";
import { Link } from "react-router-dom";
import { useAppContext } from "../../context/AppContext";

export const Logo = ({ className }) => {
  const { isMobile } = useWindowDimensions();
  const { isLoggedIn } = useAppContext();

  const mobileClassName = "text-secondary font-headline text-4xl";
  const desktopClassName = "text-secondary font-headline text-5xl";
  let logoClassName;

  if (isMobile) {
    logoClassName = className ? className : mobileClassName;
  } else {
    logoClassName = className ? className : desktopClassName;
  }

  return (
    <div to={isLoggedIn() ? "/dashboard" : "/"}>
      <h1 className={logoClassName}>Dauchi</h1>
    </div>
  );
};
