import React from "react";
import { useWindowDimensions } from "../../hooks/useWindowDimensions";
import { MobileFooter } from "./MobileFooter";
import { DesktopFooter } from "./DesktopFooter";
import { treatments } from "../../utils/treatments";
import { FaFacebookSquare } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { FaTwitterSquare } from "react-icons/fa";

export const Footer = () => {
  const { isMobile } = useWindowDimensions();
  const icons = [
    {
      icon: <AiFillInstagram />,
    },
    {
      icon: <FaFacebookSquare />,
    },
    {
      icon: <FaTwitterSquare />,
    },
  ];

  const resources = [
    {
      name: "Patient Portal",
      href: "/login",
    },
    {
      name: "Privacy Policy",
      href: "/privacy",
    },
    {
      name: "How it works",
      href: "/about_us",
    },
  ];

  const aboutUs = [
    {
      name: "Frequently Asked Questions",
      href: "/faq",
    },
  ];

  const partners = [
    {
      title: "Pharmacy Group",
      details: [
        "Honeybee Health",
        "3515 Helms Avenue",
        "Culver City, CA 90232 US",
        "(833) 466-3979",
      ],
      email: "support@honeybeehealth.com",
    },
    {
      title: "Physician Group",
      details: [
        "MDI Medical Group, P.C.",
        "447 Rose Lane",
        "Rockville Centre, NY 11570",
        "(629) 777-5752",
      ],
      email: "support@mdintegrations.com",
    },
  ];

  return isMobile ? (
    <MobileFooter
      treatments={treatments}
      resources={resources}
      aboutUs={aboutUs}
      icons={icons}
      partners={partners}
    />
  ) : (
    <DesktopFooter
      treatments={treatments}
      resources={resources}
      aboutUs={aboutUs}
      icons={icons}
      partners={partners}
    />
  );
};
