import { React, useEffect } from "react";
import { useLocation } from "react-router-dom"
import { Hero } from "../../components/hero/Hero";
import { ThreeSimpleSteps } from "../../components/threeSimpleSteps.js/ThreeSimpleSteps.js";
import { TreatmentsOffered } from "../../components/treatmentsOffered/TreatmentsOffered";
import { MoreFromDauchi } from "../../components/moreFromDauchi/MoreFromDauchi.js";
import { OurProviders } from "../../components/OurProviders/OurProviders.js";

export const HomePage = () => {
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      if (location.hash) {
        const id = location.hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          setTimeout(() => {
            const yOffset = element.getBoundingClientRect().top + window.scrollY;
            window.scrollTo({ top: yOffset, behavior: "smooth" });
          }, 100);
        }
      }
    };
    handleScroll()
  }, [location]);

  return (
    <>
      <Hero />
      <section id="three-simple-steps">
        <ThreeSimpleSteps />
      </section>
      <section id="what-we-treat">
        <TreatmentsOffered />
      </section>
      <OurProviders />
      <section id="more-from-dauchi">
        <MoreFromDauchi />
      </section>
    </>
  );
};
