import React from "react";
import ListSegmentData from "./ListSegmentData";
import MobileListSegmentData from "./MobileListSegmentData";
import MobileAboutUsData from "../../utils/aboutUs";
import { useWindowDimensions } from "../../hooks/useWindowDimensions";

const ListData = ({ data }) => {
  const { isMobile } = useWindowDimensions();

  return (
    <>
      {isMobile ? (
        <div className="bg-[#F6F5FB] grid items-center gap-6 text-left">
          {MobileAboutUsData.mobileSections.map((item, index) => (
            <div className="bg-[#FFF] mx-[20px] mt-[0px] border border-[rgba(196,188,255,1)]">
              <div className=" w-full p-[20px] pt-[0px]">
                <h4 className="pt-[10px] pb-[10px] text-[36px] font-semibold">{item.title}</h4>
                <div className="font-normal text-gray-600 text-[16px]">
                  <MobileListSegmentData data={item.content} />
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        data.map((item, index) => (
          <div key={index} className="mx[20px] my-[20px] grid items-center grid-cols-2 gap-6 text-left flex-row-reverse">
            <div className="w-full ml-[15px] ">
              <h4 className="text-3xl font-semibold">{item.title}</h4>
              <div className="mt-4 text-gray-600 text-[18px]">
                <ListSegmentData data={item.content} />
              </div>
            </div>
            <div className="hidden md:block mx-[50px] my-[50px]">
              <img src={item.image} alt="" className="w-full rounded-lg" />
            </div>
          </div>
        ))
      )}
    </>
  );
};

export default ListData;
