import React from "react";

const ListSegmentData = ({ data }) => {
  return (
    <>
      {data.map((segment, i) => {
        const isBlock = segment.contentType === "block";
        const extraBreak = isBlock && i > 0 ? <br key={`br-${i}`} /> : null;
        if (segment.type === "text") {
          return (
            <React.Fragment key={i}>
              {extraBreak}
              <span>{segment.content}</span>
            </React.Fragment>
          );
        }
        if (segment.type === "semiBoldText") {
          return (
            <React.Fragment key={i}>
              {extraBreak}
              <span className="text-[#5F51CA] font-semibold">
                {segment.content}
              </span>
            </React.Fragment>
          );
        }
        return null;
      })}
    </>
  );
};

export default ListSegmentData;
