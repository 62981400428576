// components/Faq/FaqItemList.js
import React from "react";

const ListData = ({ data }) => {
  return (
    <>
      {data.map((item, index) => (
        <div key={index} className="mb-6 flex text-left">
          <div className="w-8 h-8 flex items-center justify-center rounded-full bg-[#F5D447] text-[12px] text-[#5144B4] font-semibold mt-1 mr-4">
            {index + 1}.
          </div>
          <div className="w-full">
            <h4 className="text-lg font-semibold mb-2">{item.question_text}</h4>
            <p className="font-normal">
              {item.answer.map((segment, i) => {
                const isBlock = segment.contentType === "block";
                // If the segment is marked as block and it's not the first segment, add a line break.
                const extraBreak = isBlock && i > 0 ? <br key={`br-${i}`} /> : null;
                if (segment.type === "text") {
                  return (
                    <React.Fragment key={i}>
                      {extraBreak}
                      <span>{segment.content}</span>
                    </React.Fragment>
                  );
                }
                if (segment.type === "semiBoldText") {
                  return (
                    <React.Fragment key={i}>
                      {extraBreak}
                      <span className="text-[#5F51CA] font-semibold">
                        {segment.content}
                      </span>
                    </React.Fragment>
                  );
                }
                return null;
              })}
            </p>
          </div>
        </div>
      ))}
    </>
  );
};

export default ListData;
