// utils/faq.js
const faqData = {
  "General Questions": [
    {
      question_text: "What is Dauchi Health?",
      // The answer is now an array of segments.
      answer: [
        {
          type: "text",
          content: "Dauchi Health is a telehealth platform specializing in "
        },
        {
          type: "semiBoldText",
          content: "sexual wellness"
        },
        {
          type: "text",
          content: ". We offer "
        },
        {
          type: "text",
          content: "discreet, professional treatment for conditions like ",
          contentType: "block"
        },
        {
          type: "semiBoldText",
          content: "erectile dysfunction (ED), cold "
        },
        {
          type: "semiBoldText",
          content: "sores, genital herpes, bacterial vaginosis (BV), and yeast infections",
          contentType: "block"
        },
        {
          type: "text",
          content: "—all from the "
        },
        {
          type: "text",
          content: "comfort of your home."
        }
      ]
    },
    {
      question_text: "Who can use Dauchi Health?",
      answer: [
        {
          type: "text",
          content: "Our services are available to adults seeking treatment for sexual health concerns,"
        },
        {
          type: "text",
          content: "including men and women looking for ",
          contentType: "block"
        },
        {
          type: "semiBoldText",
          content: "fast, effective, and confidential care."
        },
      ]
    },
    {
      question_text: "Do I need an appointment?",
      answer: [
        {
          type: "text",
          content:
            "No! "
        },
        {
          type: "semiBoldText",
          content: "No appointment is needed"
        },
        {
          type: "text",
          content: "—simply complete an online consultation, and our",
        },
        {
          type: "text",
          content: " licensed healthcare providers will review your case and prescribe treatment if",
          contentType: "block"
        },
        {
          type: "text",
          content: "appropriate.",
          contentType: "block"
        }
      ]
    }
  ],
  "Treatment & Medications": [
    {
      question_text: "What conditions do you treat?",
      answer: [
        {
          type: "text",
          content: "We currently provide treatment for:",
          contentType: "block"
        },
        {
          type: "text",
          content: " ",
          contentType: "block"
        },
        {
          type: "text",
          content: " ",
          contentType: "block"
        },
        {
          type: "semiBoldText",
          content: "Erectile Dysfunction (ED)",
          contentType: "block"
        },
        {
          type: "semiBoldText",
          content: "Cold Sores & Genital Herpes",
          contentType: "block"
        },
        {
          type: "semiBoldText",
          content: "Bacterial Vaginosis (BV)",
          contentType: "block"
        },
        {
          type: "semiBoldText",
          content: "Yeast Infections",
          contentType: "block"
        }
      ]
    },
    {
      question_text: "How does the treatment process work?",
      answer: [
        {
          type: "semiBoldText",
          content: "1. Complete an online consultation",
        },
        {
          type: "text",
          content: " – Answer a few medical questions."
        },
        {
          type: "semiBoldText",
          content: "2. Provider review",
          contentType: 'block'
        },
        {
          type: "text",
          content: " – A licensed healthcare professional will evaluate your case."
        },
        {
          type: "semiBoldText",
          content: "3. Receive treatment",
          contentType: "block"
        },
        {
          type: "text",
          content: " – If approved, medication will be prescribed and shipped directly to your door."
        }
      ]
    },
    {
      question_text: "What medications do you offer?",
      answer: [
        {
          type: "text",
          content: "We offer "
        },
        {
          type: "semiBoldText",
          content: "FDA-approved",
        },
        {
          type: "text",
          content: " treatments for ED, herpes, BV, and yeast infections. Specific medication details will be provided based on your consultation."
        }
      ]
    },
    {
      question_text: "Is my treatment confidential?",
      answer: [
        {
          type: "text",
          content: "Absolutely. Your privacy is our priority. Our platform is "
        },
        {
          type: "semiBoldText",
          content: "HIPAA-compliant"
        },
        {
          type: "text",
          content: ", and all orders are shipped in "
        },
        {
          type: "semiBoldText",
          content: "discreet packaging"
        },
        {
          type: "text",
          content: "."
        }
      ]
    }
  ],
  "Billing & Insurance": [
    {
      question_text: "How much does a consultation cost?",
      answer: [
        {
          type: "text",
          content: "Our pricing varies by treatment. The consultation fee is "
        },
        {
          type: "semiBoldText",
          content: "included in the "
        },
        {
          type: "semiBoldText",
          content: "cost of your medication",
          contentType: "block"
        },
        {
          type: "text",
          content: ", so you only pay when you receive treatment."
        }
      ]
    },
    {
      question_text: "Do you accept insurance?",
      answer: [
        {
          type: "text",
          content: "At this time, ",
          contentType: "block"
        },
        {
          type: "semiBoldText",
          content: "Dauchi Health operates on a self-pay basis. "
        },
        {
          type: "text",
          content: "However, we "
        },
        {
          type: "text",
          content: "accept ",
          contentType: "block"
        },
        {
          type: "semiBoldText",
          content: "HSA/FSA payments "
        },
        {
          type: "text",
          content: "for eligible treatments."
        }
      ]
    },
    {
      question_text: "What payment methods do you accept?",
      answer: [
        {
          type: "text",
          content: "We accept "
        },
        {
          type: "semiBoldText",
          content: "major credit cards, debit cards, and HSA/FSA cards"
        },
        {
          type: "text",
          content: "."
        },
      ]
    }
  ],
  "Shipping & Returns": [
    {
      question_text: "How long does shipping take?",
      answer: [
        {
          type: "text",
          content: "Most orders are processed within "
        },
        {
          type: "semiBoldText",
          content: "24 hours "
        },
        {
          type: "text",
          content: "and shipped via "
        },
        {
          type: "semiBoldText",
          content: "fast, discreet "
        },
        {
          type: "semiBoldText",
          content: "delivery",
          contentType: "block"
        },
        {
          type: "text",
          content: "."
        }
      ]
    },
    {
      question_text: "Can I return my medication?",
      answer: [
        {
          type: "text",
          content: "Due to health and safety regulations, we cannot accept returns on "
        },
        {
          type: "text",
          content: "prescription medications. However, if your order is incorrect or damaged, ",
          contentType: "block"
        },
        {
          type: "text",
          content: "please contact us for assistance.",
          contentType: "block"
        }
      ]
    }
  ],
  "Contact & Support": [
    {
      question_text: "How do I contact customer support?",
      answer: [
        {
          type: "text",
          content: "You can reach us via email at "
        },
        {
          type: "semiBoldText",
          content: "support@dauchi.com ",
        },
        {
          type: "text",
          content: "or through our "
        },
        {
          type: "semiBoldText",
          content: "contact form "
        },
        {
          type: "text",
          content: "on "
        },
        {
          type: "text",
          content: "the website.",
          contentType: "block"
        }
      ]
    },
    {
      question_text: "What are your customer service hours?",
      answer: [
        {
          type: "text",
          content: "Our team is available "
        },
        {
          type: "semiBoldText",
          content: "Monday–Friday, 9 AM – 6 PM (PST) "
        },
        {
          type: "text",
          content: "to assist with any inquiries."
        }
      ]
    }
  ]
};

export default faqData;
