// pages/Faq.js
import React, { useState } from "react";
import aboutUsData from "../../utils/aboutUs";
import AboutUsList from "../../components/AboutUs/ListData";
import AboutUsListSegmentData from "../../components/AboutUs/ListSegmentData"
import { useWindowDimensions } from "../../hooks/useWindowDimensions";
import { Navbar } from "../../components/navbar/Navbar";
import { Footer } from "../../components/footer/Footer";
import AboutUsBanner from "../../components/AboutUs/Banner";
import GetStartedSection from "../../components/Faq/GetStarted";
import logoIcon from "../../assets/icons/faq-dauchi-logo.png";
// import MobileAboutUs from "../../components/AboutUs/MobileFaq";

function Faq() {
  const { isMobile } = useWindowDimensions();

  return (
    <div className="bg-[#fff] flex flex-col w-full">
      <Navbar />
      <AboutUsBanner />
      {isMobile ? (
          <div className="py-[20px] bg-[#F6F5FB] flex flex-col w-full min-h-screen">
            <AboutUsList data={aboutUsData.sections} />
          </div>
        ) : (
              <div className='bg-[#F6F5FB]'>
                <div className="bg-[#FFF] mx-[20px] my-[20px] rounded-[30px] min-h-screen p-[20px]">
                  <div className='grid md:grid-cols-2 gap-6 items-center mb-[20px]'>
                    <div className="items-center flex w-full rounded-[30px] p-10 text-white" style={{ backgroundColor: '#5F51CA' }}>
                      <div className="text-left w-4/5">
                        <h1 className="text-[50px] leading-[60px] font-bold">
                          {aboutUsData.banner.content.map((segment, i) => {
                            const isBlock = segment.contentType === "block";
                            // If the segment is marked as block and it's not the first segment, add a line break.
                            const extraBreak = isBlock && i > 0 ? <br key={`br-${i}`} /> : null;
                            if (segment.type === "text") {
                              return (
                                <React.Fragment key={i}>
                                  {extraBreak}
                                  <span>{segment.content}</span>
                                </React.Fragment>
                              );
                            }
                            if (segment.type === "semiBoldText") {
                              return (
                                <React.Fragment key={i}>
                                  {extraBreak}
                                  <span className="text-[#F5D447] font-semibold">
                                    {segment.content}
                                  </span>
                                </React.Fragment>
                              );
                            }
                            return null;
                          })}
                        </h1>
                      </div>
                      <div className='w-1/5 flex justify-end'>
                        <img src={logoIcon} alt='Dauchi Logo' className="w-[75px]" />
                      </div>
                    </div>
                    <div className="ml-[50px] w-full">
                      <div className="text-left text-gray-800 space-y-2 text-[18px]">
                        <AboutUsListSegmentData data={aboutUsData.banner.sections.content} />
                      </div>
                    </div>
                  </div>
                  <AboutUsList data={aboutUsData.sections} />
                </div>
              </div>
            )
        }
      <GetStartedSection />
      <Footer />
    </div>
  );
}

export default Faq;
