import Medicine from "../../assets/images/Medicine.png";
import Snapshot from "../../assets/images/snapshot.png";
import ContactUs from "../../assets/images/ContactUs.png";
import Logo from "../../assets/images/DauLogo.png";
import QuestionMark from "../../assets/images/question_mark.png";
import QuestionMark2 from "../../assets/images/question_mark1.png";
import QuestionMark3 from "../../assets/images/question_mark2.png";
import Provider from "../../assets/images/OurProvider.png";
import { DAUCHI_SUPPORT_EMAIL } from "../../constants/Common";
import { Link } from "react-router-dom";

export const DesktopMoreFromDauchi = () => {
  return (
    <div className="bg-primary ">
      <div className="flex flex-col items-start px-[3%] py-12">
        <h1 className="text-secondary text-[3rem] lg:text-[3.5rem] 2xl:text-[4.43rem]">
          More from Dauchi
        </h1>
        <div className="text-secondary text-[1.37rem] lg:text-[1.62rem] 2xl:text-3xl">
          Everything you need from A to Z
        </div>
      </div>
      <div className="flex flex-col items-center md:px-6 lg:px-10 xl:px-14 2xl:px-20 md:gap-1 lg:gap-2 xl:gap-4 2xl:gap-6 md:pb-[5rem] xl:pb-[9.33rem]">
        <div className="flex w-full custom:max-w-[110rem] md:gap-1 lg:gap-2 xl:gap-4 2xl:gap-6">
          <Link
            to="/#what-we-treat"
            className="flex relative w-[54%] justify-between bg-mediumPrimary border-[1px] border-white md:h-[15rem] lg:h-[18rem] xl:h-[21rem] 2xl:h-[23rem] custom:h-[25rem] rounded-xl"
          >
            <div className="md:py-2 xl:py-4 md:pl-[1rem] xl:pl-[1.5rem] custom:pl-[2.125rem]">
              <h1 className="text-secondary text-start md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-[3.5rem] custom:text-[4rem] font-subHeadline 2xl:w-[26rem] custom:w-[29.63rem]">
                What We Treat
              </h1>
              <p className="text-white text-start md:text-sm lg:text-md xl:text-lg custom:text-[1.25rem] md:w-[14rem] lg:w-[19rem] xl:w-[23rem] 2xl:w-[25rem] custom:w-[27rem] font-subHeadline">
                We have a wide range of treatments. Check them out.
              </p>
            </div>
            <img
              src={Medicine}
              alt="Medicine"
              className="absolute right-0 rounded-xl md:h-[14.76rem] lg:h-[17.8rem] xl:h-[20.8rem] 2xl:h-[22.7rem] custom:h-[24.75rem]"
            />
          </Link>
          <Link
            to="/login"
            className="flex md:w-[46%] lg:w-[46%] 2xl:w-[46%] custom:w-[48%] justify-between bg-secondary border-[1px] border-white md:h-[15rem] lg:h-[18rem] xl:h-[21rem] 2xl:h-[23rem] custom:h-[25rem] rounded-xl"
          >
            <div className="flex items-end md:pl-4 xl:pl-6 2xl:pl-10 custom:pl-12">
              <img
                src={Snapshot}
                alt="snapshot"
                className="md:h-[10rem] lg:h-[12rem] xl:h-[14rem] 2xl:h-[17rem] custom:h-[21rem]"
              />
            </div>
            <div className="md:my-[4rem] lg:my-[5rem] xl:my-[4rem] 2xl:my-20 custom:my-[5.5rem] md:px-[0.5rem] lg:px-[1rem] xl:px-[1.5rem] 2xl:px-[2.5rem] custom:px-[3.5rem]">
              <h1 className="text-darkPrimary text-start md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-[3.5rem] custom:text-[4rem] font-subHeadline md:leading-[1.7rem] lg:leading-[2rem] xl:leading-[3rem] 2xl:leading-[4rem] custom:leading-[4.5rem] md:w-[7rem] lg:w-[9rem] xl:w-[11rem] 2xl:w-[13rem] custom:w-[15rem]">
                Patient Portal
              </h1>
              <p className="text-darkPrimary text-start md:text-sm lg:text-md xl:text-lg custom:text-[1.25rem] font-subHeadline md:w-[11rem] lg:w-[12rem] xl:w-[15rem] 2xl:w-[16.5rem] custom:w-[18rem] mt-1">
                Manage your subscriptions in our new patient portal.
              </p>
            </div>
          </Link>
        </div>
        <div className="flex custom:max-w-[110rem] md:gap-1 lg:gap-2 xl:gap-4 2xl:gap-6 w-full">
          <div className="flex flex-col md:gap-1 lg:gap-2 xl:gap-4 2xl:gap-6">
            <a
              href={`mailto:${DAUCHI_SUPPORT_EMAIL}`}
              className="flex flex-col justify-between bg-darkPrimary md:h-[10rem] lg:h-[12rem] xl:h-[14rem] 2xl:h-[16rem] custom:h-[18.125rem] md:pl-[1rem] xl:pl-[1.5rem] 2xl:pl-[2.125rem] rounded-xl border-[1px] border-white"
            >
              <div>
                <h1 className="text-white text-start md:text-2xl lg:text-3xl xl:text-4xl md:pt-1 xl:pt-2 2xl:text-[3.5rem] custom:text-[4rem] font-subHeadline">
                  Contact Us
                </h1>
                <p className="text-white text-start md:text-sm lg:text-md xl:text-lg custom:text-[1.25rem] font-subHeadline mt-1">
                  Shoot your shot. Slide into our DM’s!
                </p>
              </div>
              <div className="flex md:pl-[4rem] xl:pl-[9rem] 2xl:pl-[11.765rem]">
                <img src={ContactUs} alt="contact-us" />
              </div>
            </a>
            <Link
              to="/about_us"
              className="flex md:h-[10rem] lg:h-[12rem] xl:h-[14rem] 2xl:h-[16rem] custom:h-[18.125rem] justify-between md:px-[1rem] xl:px-[1.5rem] 2xl:px-[2.125rem] pb-2 bg-lightSecondary rounded-xl border-[1px] border-white"
            >
              <div>
                <h1 className="text-darkPrimary md:text-2xl md:pt-1 xl:pt-2 text-start lg:text-3xl xl:text-4xl 2xl:text-[3.5rem] custom:text-[4rem] font-subHeadline">
                  About
                </h1>
                <p className="text-darkPrimary text-start md:text-sm lg:text-md xl:text-lg custom:text-[1.25rem] font-subHeadline mt-1">
                  Curious about us? Aw shucks.
                </p>
              </div>
              <div className="flex items-center">
                <img
                  src={Logo}
                  alt="dauchi-logo"
                  className="md:h-28 xl:h-32 2xl:h-40"
                />
              </div>
            </Link>
          </div>
          <Link
            to="/faq"
            className="flex w-[30%] flex-col bg-lightPrimary rounded-xl border-[1px] border-white"
          >
            <div className="md:pl-[1rem] xl:pl-[1.5rem] 2xl:pl-[2.125rem] pr-4 md:pb-16 xl:pb-20 2xl:pb-32">
              <h1 className="text-darkPrimary md:pt-1 xl:pt-2 text-start md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-[3.5rem] custom:text-[4rem] font-subHeadline">
                FAQ's
              </h1>
              <p className="text-darkPrimary text-start md:text-sm lg:text-md xl:text-lg custom:text-[1.25rem] font-subHeadline mt-1 md:w-[10rem] lg:w-[12.5rem] xl:w-[16rem] 2xl:w-[20rem]">
                You’ve got questions? We’ve got answers
              </p>
            </div>
            <div className="flex justify-between">
              <img
                src={QuestionMark}
                alt="question-mark"
                className="md:h-[6rem] lg:h-[9rem] xl:h-[12rem] 2xl:h-[14rem] custom:h-[16rem]"
              />
              <img
                src={QuestionMark3}
                alt="question-mark"
                className="md:h-[6rem] lg:h-[9rem] xl:h-[12rem] 2xl:h-[14rem] custom:h-[16rem]"
              />
              <img
                src={QuestionMark2}
                alt="question-mark"
                className="md:h-[6rem] lg:h-[9rem] xl:h-[12rem] 2xl:h-[14rem] custom:h-[16rem]"
              />
            </div>
          </Link>
          <div className="flex items-end relative md:w-[22rem] lg:w-[20rem] xl:w-[25rem] 2xl:w-[30rem] custom:w-[33.5rem] bg-primary rounded-xl border-[1px] border-white">
            <div>
              <img
                src={Provider}
                alt="our-provider"
                className="md:h-[15rem] lg:h-[19rem] xl:h-[24rem] 2xl:h-[30rem] custom:h-[35rem]"
              />
            </div>
            <div className="absolute md:top-8 lg:top-8 right-0">
              <h1 className="text-white text-start md:leading-[1.7rem] lg:leading-[2rem] xl:leading-[3rem] 2xl:leading-[4rem] custom:leading-[4.5rem] md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-[3rem] custom:text-[4rem] font-subHeadline md:w-[7rem] lg:w-[10rem] xl:w-[12.5rem] 2xl:w-[15rem] custom:w-[19rem]">
                Our Providers
              </h1>
              <p className="text-white text-start md:text-sm lg:text-md xl:text-lg custom:text-[1.25rem] font-subHeadline mt-1 md:w-[8rem] lg:w-[9rem] xl:w-[12rem] 2xl:w-[15rem] custom:w-[18rem]">
                We’ve got a great group. They’ve got you covered!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
