import "./App.scss";
import React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import { HomePage } from "./pages/homePage/HomePage";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Layout } from "./pages/layout/Layout";
import { PatientPortalPage } from "./pages/patientPortalPage/PatientPortalPage";
import { NoPage } from "./pages/noPage/NoPage";
import { GenitalHerpes } from "./pages/genitalHerpesPage/GenitalHerpesPage";
import { HerpesPreliminarySurvey } from "./pages/herpesPreliminarySurveyPage/HerpesPreliminarySurveyPage";
import Dashboard from "./pages/Dashboard";
import ComingSoon from "./pages/ComingSoon";
import { AppProvider } from "./context/AppContext";
import Login from "./pages/Login";
import Messages from "./pages/Messages";
import { ProtectedRoute } from "./components/ProtectedRoute";
import PasswordChange from "./pages/Password/Change";
import TreatmentPage from "./pages/TreatmentPage";
import EdPreliminarySurvey from "./pages/edPreliminarySurveyPage";
import UTIPreliminarySurvey from "./pages/utiPreliminarySurveyPage";
import YeastPreliminarySurvey from "./pages/yeastPreliminarySurveyPage";
import BvPreliminarySurvey from "./pages/bvPreliminarySurveyPage";
import Privacy from "./pages/Privacy/privacy";
import WithAxios from "./components/WithAxios";
import ScrollToTop from "./components/ScrollToTop";
import { pdfjs } from "react-pdf";
import Faq from "./pages/Faq";
import AboutUs from "./pages/AboutUs";

function App() {
  const queryClient = new QueryClient();
  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <AppProvider>
          <WithAxios>
            <div className="App">
              <div className="bg-primary h-full">
                <ScrollToTop />
                <Routes>
                  <Route path="/" element={<Layout />}>
                    <Route index element={<HomePage />} />
                    <Route
                      path="patient_portal"
                      element={<PatientPortalPage />}
                    />
                    <Route
                      path="genital_herpes/herpes_preliminary_survey/*"
                      element={<HerpesPreliminarySurvey />}
                    />
                    <Route
                      path="erectile_dysfunction/ed_preliminary_survey/*"
                      element={<EdPreliminarySurvey />}
                    />

                    <Route
                      path="urinary_tract_infection/uti_preliminary_survey/*"
                      element={<UTIPreliminarySurvey />}
                    />

                    <Route
                      path="yeast_infection/yeast_preliminary_survey/*"
                      element={<YeastPreliminarySurvey />}
                    />

                    <Route
                      path="bacterial_vaginosis/bv_preliminary_survey/*"
                      element={<BvPreliminarySurvey />}
                    />

                    {[
                      "urinary_tract_infection",
                      "birth_control",
                      "erectile_dysfunction",
                      "yeast_infection",
                      "bacterial_vaginosis",
                      "cold_sore",
                      "genital_herpes",
                    ].map((path) => (
                      <Route
                        path={path}
                        element={<TreatmentPage path={path} />}
                      />
                    ))}
                    <Route
                      path="genital_herpes/herpes_preliminary_survey/*"
                      element={<HerpesPreliminarySurvey />}
                    />
                    <Route path="*" element={<NoPage />} />
                    <Route path="/login" element={<Login />} />
                    {/* Dashboard */}
                    <Route
                      path="/dashboard/*"
                      element={
                        <ProtectedRoute redirectTo={"/login"}>
                          <Dashboard />
                        </ProtectedRoute>
                      }
                    />
                    <Route path="/privacy" element={<Privacy />} />
                    <Route path="/coming_soon" element={<ComingSoon />} />
                  </Route>
                  <Route path="/password/change" element={<PasswordChange />} />
                  <Route path="/faq" element={<Faq />} />
                  <Route path="/about_us" element={<AboutUs />} />
                </Routes>
              </div>
            </div>
          </WithAxios>
        </AppProvider>
      </BrowserRouter>
      <ReactQueryDevtools initialIsOpen={true} />
    </QueryClientProvider>
  );
}

export default App;
