import React from "react";
import bannerAsset from "../../assets/images/faqTitleBanner.png";
import mobileBannerAsset from "../../assets/images/faqTitleBannerForMobile.png";

const Banner = () => {
  return (
    <div className="md:bg-[#F6F5FB] bg-[#5F51CA]">
      <div className="md:mx-[20px] md:mt-[20px] relative border-[20px] border-[#5F51CA] rounded-[30px] md:h-[600px] h-[480px] flex items-center justify-center overflow-hidden">
        <div
          className="absolute inset-[-10px] hidden md:block bg-cover bg-center"
          style={{ backgroundImage: `url(${bannerAsset})` }}
        ></div>
        <div
          className="absolute inset-[-10px] block md:hidden bg-cover bg-center"
          style={{ backgroundImage: `url(${mobileBannerAsset})` }}
        ></div>
        <div className="absolute inset-0 bg-[#5F51CA] opacity-75"></div>
        <h1 className="relative lg:text-[200px] md:text-[120px] text-[54px] lg:leading-[150px] md:leading-[100px] leading-[50px] font-bold text-white text-left">
          Frequently <br />
          Asked <br />
          Questions
        </h1>
      </div>
    </div>
  );
};

export default Banner;
