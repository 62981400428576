import React, { useState } from "react";
import faqData from "../../utils/faq";

// Import your arrow icons
import arrowRightIcon from "../../assets/icons/accordion_right_arrow.png";
import arrowDownIcon from "../../assets/icons/accordion_down_arrow.png";

function MobileFaq() {
  const [openQuestion, setOpenQuestion] = useState(null);
  const categories = Object.entries(faqData);

  const handleToggle = (questionId) => {
    setOpenQuestion((prev) => (prev === questionId ? null : questionId));
  };

  return (
    <div className="p-4 space-y-6 bg-[#F6F5FB] min-h-screen">
      {categories.map(([categoryName, questions]) => (
        <div key={categoryName} className="rounded-[4px] border-[1.5px] border-solid border-[#C4BCFF]">
          <h2 className="text-left px-[20px] py-[10px] bg-[#5F51CA] text-white text-[16px] font-semibold rounded-t-[2px]">{categoryName}</h2>

          <div>
            {questions.map((question, index) => {
              const questionId = `${categoryName}-${index}`;
              const isOpen = openQuestion === questionId;
              const isLast = index === questions.length - 1;

              return (
                <div className='rounded-[4px] bg-white p-[20px] pb-0'>
                  <div key={questionId} className={`border-b-2 border-solid border-[#F5D447] pb-[20px] ${isLast ? "border-b-0" : ""}`}>
                    <button
                      onClick={() => handleToggle(questionId)}
                      className="flex items-center justify-between w-full py-2"
                    >
                      <span className="text-[16px] text-black-800 font-medium">
                        {question.question_text}
                      </span>
                      <img
                        src={isOpen ? arrowDownIcon : arrowRightIcon}
                        alt="accordion arrow"
                        className="h-5 w-5"
                      />
                    </button>
                    <div
                      className={`
                        text-[14px] px-5 pl-0 text-black-400 overflow-hidden transition-all
                        duration-300 ease-in-out text-left
                        ${isOpen ? "py-3" : "max-h-0 py-0"}
                      `}
                    >
                      {question.answer.map((segment, i) => {
                        const isBlock = segment.contentType === "block";
                        const extraBreak = isBlock && i > 0 ? <br key={`br-${i}`} /> : null;
                        if (segment.type === "text" || segment.type === "semiBoldText") {
                          return (
                            <React.Fragment key={i}>
                              {extraBreak}
                              <span>{segment.content}</span>
                            </React.Fragment>
                          );
                        }
                        return null;
                      })}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ))}
    </div>
  );
}

export default MobileFaq;
