import React from "react";
import ContactAndSupportIcon from "../../assets/icons/faq-contact-icon.png";
import returnHomeIcon from "../../assets/icons/arrow_top_left.png";
import returnArrowBackLeftIcon from "../../assets/icons/arrow_back_left.png";
import ListData from "./ListData";
import faqData from "../../utils/faq";

function ContactAndSupport({ data = [], selectedCategory, setSelectedCategory }) {
  const categories = Object.keys(faqData);
  const currentIndex = categories.indexOf(selectedCategory);

  return (
    <div className="flex flex-col min-h-[80vh]">
      <div className="flex my-5 mx-5 justify-end">
        <a href="/" className="flex items-center space-x-2">
          <span className="text-[#5F51CA] font-semibold">Return Home</span>
          <img src={returnHomeIcon} alt="Return Home" className="h-6 w-6" />
        </a>
      </div>
      <div className="mx-[140px] flex-grow">
        <div className="flex items-center cursor-pointer my-6">
          <h2 className="text-2xl font-semibold text-left mr-2">
            Contact & Support
          </h2>
          <span className="mr-2">
            <img
              src={ContactAndSupportIcon}
              alt="Contact And Support Icon"
              className="h-5 w-5"
            />
          </span>
        </div>
        <ListData data={data} />
      </div>
      <div className="flex my-5 mx-5 justify-end">
        <button
          onClick={() => {
            if (currentIndex > 0) {
              setSelectedCategory(categories[currentIndex - 1]);
            }
          }}
          className="flex items-center space-x-2 pl-[30px]"
        >
          <img
            src={returnArrowBackLeftIcon}
            alt="Previous Category"
            className="h-[48px] w-[48px]"
          />
        </button>
      </div>
    </div>
  );
}

export default ContactAndSupport;
