// pages/Faq.js
import React, { useState } from "react";
import faqData from "../../utils/faq";

// Import your category components
import GeneralQuestions from "../../components/Faq/GeneralQuestions";
import TreatmentsAndMedications from "../../components/Faq/TreatmentsAndMedications";
import BillingAndInsurance from "../../components/Faq/BillingAndInsurance";
import ShippingAndReturns from "../../components/Faq/ShippingAndReturns";
import ContactAndSupport from "../../components/Faq/ContactAndSupport";
import { useWindowDimensions } from "../../hooks/useWindowDimensions";
import { Navbar } from "../../components/navbar/Navbar";
import { Footer } from "../../components/footer/Footer";
import FaqBanner from "../../components/Faq/Banner";
import GetStartedSection from "../../components/Faq/GetStarted";
import MobileFaq from "../../components/Faq/MobileFaq";
import generalIcon from "../../assets/icons/faq-general-icon.png";
import treatmentIcon from "../../assets/icons/faq-treatment-icon.png";
import billingIcon from "../../assets/icons/faq-billing-icon.png";
import shippingIcon from "../../assets/icons/faq-shipping-icon.png";
import contactIcon from "../../assets/icons/faq-contact-icon.png";
import logoIcon from "../../assets/icons/faq-dauchi-logo.png";
import lineIcon from "../../assets/icons/faq-logo-line.png"

function Faq() {
  // Keep track of which category is selected
  const [selectedCategory, setSelectedCategory] = useState("General Questions");

  // Conditionally render the correct component
  const renderCategoryContent = () => {
    if (selectedCategory === "General Questions") {
      return (
        <GeneralQuestions
          data={faqData["General Questions"]}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
        />
      );
    } else if (selectedCategory === "Treatment & Medications") {
      return (
        <TreatmentsAndMedications
          data={faqData["Treatment & Medications"]}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
        />
    );
    }
    else if (selectedCategory === "Billing & Insurance") {
      return (
        <BillingAndInsurance
          data={faqData["Billing & Insurance"]}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
        />
    );
    }
    else if (selectedCategory === "Shipping & Returns") {
      return (
        <ShippingAndReturns
          data={faqData["Shipping & Returns"]}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
        />
    );
    }
    else if (selectedCategory === "Contact & Support") {
      return (
        <ContactAndSupport
          data={faqData["Contact & Support"]}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
        />
    );
    }
    return null;
  };
  const { isMobile } = useWindowDimensions();

  return (
    <div className="bg-[#fff] flex flex-col w-full">
      <Navbar />
      <FaqBanner />
      {isMobile ? (
        <div className="bg-[#fff] flex flex-col w-full min-h-screen">
          <MobileFaq />
        </div>
       ) : (
        <div className="md:bg-[#F6F5FB]">
          <div className="mx-[20px] my-[10px] border-[20px] border-[#5F51CA] rounded-[30px] flex min-h-[80vh]">
            <aside className="w-1/4 bg-[#5F51CA] text-white p-6 flex flex-col">
              <h2 className="font-semibold mb-6 text-2xl tracking-normal text-left ml-8">
                Frequently <br />Asked Questions
              </h2>
              <nav>
                <ul className="space-y-4">
                  <li
                    className={`flex items-center cursor-pointer hover:text-gray-300 ${
                      selectedCategory === "General Questions" && "font-semibold text-[#F5D447]"
                    }`}
                    onClick={() => setSelectedCategory("General Questions")}
                  >
                    <span className="mr-2">
                      <img
                        src={generalIcon}
                        alt="General Questions Icon"
                        className="h-5 w-5"
                      />
                    </span>
                    <span>General Questions</span>
                  </li>
                  <li
                    className={`flex items-center cursor-pointer hover:text-gray-300 ${
                      selectedCategory === "Treatment & Medications" &&
                      "font-semibold text-[#F5D447]"
                    }`}
                    onClick={() =>
                      setSelectedCategory("Treatment & Medications")
                    }
                  >
                    <span className="mr-2">
                      <img
                        src={treatmentIcon}
                        alt="Treatment & Medications Icon"
                        className="h-5 w-5"
                      />
                    </span>
                    <span>Treatment & Medications</span>
                  </li>
                  <li
                    className={`flex items-center cursor-pointer hover:text-gray-300 ${
                      selectedCategory === "Billing & Insurance" &&
                      "font-semibold text-[#F5D447]"
                    }`}
                    onClick={() =>
                      setSelectedCategory("Billing & Insurance")
                    }
                  >
                    <span className="mr-2">
                      <img
                        src={billingIcon}
                        alt="Billing & Insurance Icon"
                        className="h-5 w-5"
                      />
                    </span>
                    <span>Billing & Insurance</span>
                  </li>
                  <li
                    className={`flex items-center cursor-pointer hover:text-gray-300 ${
                      selectedCategory === "Shipping & Returns" &&
                      "font-semibold text-[#F5D447]"
                    }`}
                    onClick={() =>
                      setSelectedCategory("Shipping & Returns")
                    }
                  >
                    <span className="mr-2">
                      <img
                        src={shippingIcon}
                        alt="Shipping & Returns Icon"
                        className="h-5 w-5"
                      />
                    </span>
                    <span>Shipping & Returns</span>
                  </li>
                  <li
                    className={`flex items-center cursor-pointer hover:text-gray-300 ${
                      selectedCategory === "Contact & Support" &&
                      "font-semibold text-[#F5D447]"
                    }`}
                    onClick={() =>
                      setSelectedCategory("Contact & Support")
                    }
                  >
                    <span className="mr-2">
                      <img
                        src={contactIcon}
                        alt="Contact & Support Icon"
                        className="h-5 w-5"
                      />
                    </span>
                    <span>Contact & Support</span>
                  </li>
                </ul>
              </nav>
              <div className="relative w-full mt-auto mb-8">
                <img src={lineIcon} alt="Styled Line" className="w-full" />
                <img src={logoIcon} alt="Logo" className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 w-16" />
              </div>
            </aside>
            <main className="w-3/4 bg-white">
              {renderCategoryContent()}
            </main>
          </div>
        </div>
       )}
      <GetStartedSection />
      <Footer />
    </div>
  );
}

export default Faq;
