import aboutUsWhatWeDoIcon from "../assets/images/aboutUsWhatWeDo.png";
import aboutUsOurMissionIcon from "../assets/images/aboutUsOurMission.png";
import aboutUsWhyChooseIcon from "../assets/images/aboutUsWhyChooseUs.png";

const aboutUsData = {
  banner: {
    background: "#5F51CA",
    textColor: "text-white",
    content: [
      {
        type: "text",
        content: "Your Trusted"
      },
      {
        type: "text",
        content: "Partner in ",
        contentType: "block"
      },
      {
        type: "semiBoldText",
        content: "Sexual "
      },
      {
        type: "semiBoldText",
        content: "Wellness",
        contentType: "block"
      }
    ],
    sections: {
      content: [
        { type: "text", content: "At "},
        { type: "semiBoldText", content: "Dauchi Health" },
        { type: "text", content: "we believe that " },
        { type: "semiBoldText", content: "sexual health is essential to overall " },
        { type: "semiBoldText", content: "well-being", contentType: "block" },
        { type: "text", content: ". Our mission is to provide fast, discreet, and " },
        { type: "text", content: "effective ", contentType: "block"}, 
        { type: "semiBoldText", content: "telehealth solutions " },
        { type: "text", content: "for common sexual health conditions—"},
        { type: "text", content: "including ", contentType: "block"},
        { type: "semiBoldText", content: " erectile dysfunction (ED), cold sores, genital herpes,"},
        { type: "semiBoldText", content: " bacterial vaginosis (BV), and yeast infections.", contentType: "block"},
        { type: "text", content: "", contentType: "block" },
        { type: "text", content: "", contentType: "block" },
        { type: "text", content: "With " },
        { type: "semiBoldText", content: " licensed healthcare providers " },
        { type: "text", content: "and " },
        { type: "semiBoldText", content: "FDA-approved treatments" },
        { type: "text", content: " , we " },
        { type: "text", content: "make it easy for you to ", contentType: "block" },
        { type: "semiBoldText", content: "get the care you need—on your terms, from " },
        { type: "semiBoldText", content: "the comfort of your home.", contentType: "block" }
      ],
    }
  },
  sections: [
    {
      title: "What We Do",
      content: [
        { type: "text", content: "Dauchi Health is a "},
        { type: "semiBoldText", content: "modern telehealth platform "},
        { type: "text", content: "designed to "},
        { type: "text", content: "remove the stigma and inconvenience of seeking treatment for ", contentType: "block"},
        { type: "text", content: "sexual health concerns. We offer:", contentType: "block"},
        { type: "text", content: "", contentType: "block"},
        { type: "semiBoldText", content: "• No Appointments Needed – ", contentType: "block" },
        { type: "text", content: "Start your consultation online in "},
        { type: "text", content: "minutes.", contentType: "block"},
        { type: "semiBoldText", content: "• Discreet & Professional Care – ", contentType: "block" },
        { type: "text", content: "Your privacy is our priority."},
        { type: "semiBoldText", content: "• Fast Prescriptions & Delivery – ", contentType: "block" },
        { type: "text", content: "If approved, we’ll ship your"},
        { type: "text", content: "medication directly to you.", contentType: "block" },
        { type: "semiBoldText", content: "• Expert Support – ", contentType: "block" },
        { type: "text", content: "Our licensed providers are here to help you "},
        { type: "text", content: "every step of the way.", contentType: "block" }
      ],
      image: aboutUsWhatWeDoIcon,
    },
    {
      title: "Our Mission",
      content: [
        { type: "text", content: "We’re committed to " },
        { type: "semiBoldText", content: "normalizing sexual health conversations " },
        { type: "text", content: "and " },
        { type: "text", content: "providing ", contentType: "block" },
        { type: "semiBoldText", content: "accessible, stigma-free care" },
        { type: "text", content: ". No one should feel " },
        { type: "text", content: "embarrassed or hesitant about seeking treatment for their health. ", contentType: "block" },
        { type: "text", content: "That’s why ", contentType: "block" },
        { type: "semiBoldText", content: "Dauchi Health " },
        { type: "text", content: "makes high-quality care simple, " },
        { type: "text", content: "confidential, and affordable.", contentType: "block" },
      ],
      image: aboutUsOurMissionIcon,
    },
    {
      title: "Why Choose Us?",
      content: [
        { type: "semiBoldText", content: "🔹 Convenience" },
        { type: "text", content: "– No doctor visits, no waiting rooms—just fast, " },
        { type: "text", content: "online care.", contentType: "block" },
        { type: "semiBoldText", content: "🔹 Privacy ", contentType: "block" },
        { type: "text", content: "– 100% secure platform with HIPAA-compliant data " },
        { type: "text", content: "protection.", contentType: "block" },
        { type: "semiBoldText", content: "🔹 Affordable ", contentType: "block" },
        { type: "text", content: "– Transparent pricing with no hidden fees." },
        { type: "semiBoldText", content: "🔹 Trusted Expertise ", contentType: "block" },
        { type: "text", content: "– We work with licensed U.S. healthcare " },
        { type: "text", content: "professionals to ensure safe and effective treatment.", contentType: "block" },
      ],
      image: aboutUsWhyChooseIcon,
    },
  ],
  mobileSections: [
    {
      title: "Your Trusted Partner in Sexual Wellness",
      content: [
        { type: "text", content: "At Dauchi Health, we believe that sexual health is essential to overall well-being. Our mission is to provide fast, discreet, and effective telehealth solutions for common sexual health conditions—including erectile dysfunction (ED), cold sores, genital herpes, bacterial vaginosis (BV), and yeast infections." },
        { type: "text", content: "", contentType: "block" },
        { type: "text", content: "With licensed healthcare providers and FDA-approved treatments, we make it easy for you to get the care you need—on your terms, from the comfort of your home.", contentType: "block" }
      ],
    },
    {
      title: "What We Do",
      content: [
        { type: "text", content: "Dauchi Health is a modern telehealth platform designed to remove the stigma and inconvenience of seeking treatment for sexual health concerns. We offer:" },
        { type: "text", content: "", contentType: "block" },
        { type: "text", content: "• No Appointments Needed – Start your consultation online in minutes.", contentType: "block" },
        { type: "text", content: "• Discreet & Professional Care – Your privacy is our priority.", contentType: "block" },
        { type: "text", content: "• Fast Prescriptions & Delivery – If approved, we’ll ship your medication directly to you.", contentType: "block" },
        { type: "text", content: "• Expert Support – Our licensed providers are here to help you every step of the way.", contentType: "block" }
      ],
    },
    {
      title: "Our Mission",
      content: [
        { type: "text", content: "We’re committed to normalizing sexual health conversations and providing accessible, stigma-free care. No one should feel embarrassed or hesitant about seeking treatment for their health. That’s why Dauchi Health makes high-quality care simple, confidential, and affordable." },
      ],
    },
    {
      title: "Why Choose Dauchi Health?",
      content: [
        { type: "text", content: "🔹Convenience – No doctor visits, no waiting rooms—just fast, online care." },
        { type: "text", content: "", contentType: "block" },
        { type: "text", content: "🔹 Privacy – 100% secure platform with HIPAA-compliant data protection.", contentType: "block" },
        { type: "text", content: "", contentType: "block" },
        { type: "text", content: "🔹 Affordable – Transparent pricing with no hidden fees.", contentType: "block" },
        { type: "text", content: "", contentType: "block" },
        { type: "text", content: "🔹 Trusted Expertise – We work with licensed U.S. healthcare professionals to ensure safe and effective treatment.", contentType: "block" },
      ],
    },
  ],
};
export default aboutUsData;








