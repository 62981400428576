// components/GetStartedSection.js
import React from 'react';
import getStartedBg from "../../assets/images/faqGetStartedBanner.png";
import getStartedBgForMobile from "../../assets/images/faqGetStartedBannerForMobile.png";
import { useNavigate } from "react-router-dom";

const GetStarted = () => {
  const navigate = useNavigate();

  return (
    <div className="bg-[#F6F5FB]">
      <div className="relative md:h-[600px] h-[430px] mx-[20px] mt-[10px] mb-[30px] flex items-center overflow-hidden md:rounded-[30px] rounded-[2px]">
        <div className="absolute inset-0 hidden md:block bg-cover bg-center"
          style={{
            backgroundImage: `url(${getStartedBg})`
          }}> 
        </div>
        <div className="absolute inset-[-5px] block md:hidden bg-cover bg-center"
          style={{
            backgroundImage: `url(${getStartedBgForMobile})`
          }}> 
        </div>
        <div className="absolute inset-0 md:rounded-[30px]"></div>
        <div className="md:w-[35%] md:h-[575px] lg:h-[550px] relative z-10 mx-[15px] my-[15px] md:rounded-[30px] rounded-[2px] flex items-center bg-[#483DA0E5]">
          <div className="w-full flex flex-col p-8">
            <h2 className="md:text-[70px] md:leading-[70px] text-[40px] leading-[40px] font-semibold text-white mb-4 md:text-left">
              Get Started <br />
              Today
            </h2>
            <p className="hidden md:block text-lg text-[15px] text-white mb-6 text-left">
              Take control of your sexual <br />
              wellness with Dauchi Health. Whether you're <br />
              looking for treatment options, expert advice, or a discreet solution, we're here to help.
              <br />
              <br />
              Start your online consultation today!
            </p>
            <p className="text-[15px] block md:hidden text-white mb-6 md:text-left">
              Take control of your sexual
              wellness with Dauchi Health. Whether you're
              looking for treatment options, expert advice, or a discreet solution, we're here to help.
              <br />
              <br />
              Start your online consultation today!
            </p>
            <button
              type="submit"
              className={`h-[60px] w-full bg-secondary font-semibold text-[1.25rem] text-darkPrimary py-[7px] rounded-full shadow-[0_4px_10px_rgba(0,0,0,1)]`}
              onClick={() => navigate("/")}
            >
              GET STARTED
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetStarted;
